"use client";

import type { FC } from "react";
import Image from "next/image";

const NotFoundPage: FC = () => {
  return (
    <div>
      <div className="glowingBackdrop" />

      <main>
        404
        <h1 className="special -mt-4">Not found!</h1>
        <div className="my-4 flex items-center justify-center">
          <Image
            src="/static/images/node-mascot.svg"
            alt="The Node.js mascot"
            height={114.69}
            width={100}
          />
        </div>
        <p className="-mt-4 max-w-sm text-center text-lg">
          Not found description
        </p>
        <a href="/">
          not found back to home
          {/* <ArrowRightIcon /> */}
        </a>
      </main>
    </div>
  );
};

export default NotFoundPage;
